import './landingPage.scss';
import {Container, Navbar, Form, Nav} from "react-bootstrap";
import logo from '../assets/images/logo.png'
import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import {FormRepo} from "../repo/FormRepo";
import {AllToasts} from "../components/toast/AllToast";
type formData={
    name:string,
    emailAddress:string,
    description:string
}
const LandingPage = () => {
    const [loading, setLoading]= useState<boolean>(false)
    const [formData, setFormData] = useState({
        name: '',
        emailAddress: '',
        description: '',
    });

    const handleChange = (e:ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const onSubmit = (e:FormEvent) => {
        e.preventDefault();
        setLoading(true);
        console.log('31',formData)
        FormRepo.submit(formData).then((res)=>{
            setFormData({emailAddress: "", description: "", name: ""});
            AllToasts.success('Subscribed Successfully',10000);
        }).catch((error)=>{
            console.error(error)
        }).finally(()=> setLoading(false))
    }
    return <div className={'page_wrapper'}>
        <Navbar className="bg-light py-3">
            <Container>
                <Navbar.Brand href="#home">
                    <img
                        src={logo}
                        height="35"
                        className="d-inline-block align-top"
                    />
                </Navbar.Brand>
                <Nav className="ml-auto">
                    <Nav.Link href="https://portal.dodda.ai/login" style={{ color: 'blue' }}>Login</Nav.Link>
                </Nav>
            </Container>
        </Navbar>
        <section className={'hero_banner py-5'}>
            <div className={'container'}>
                <div className={'row'}>
                    <div className={'col-md-5'}>
                        <div className={'left_info'}>
                            <h2>Bridging gaps between Artificial Intelligence & Businesses</h2>
                            <p>Coming next... Beta release and product portfolio</p>
                        </div>
                        <form className={'mb-4'} onSubmit={onSubmit}>
                            <div className={'row'}>
                                <Form.Group className="col-6 mb-3" controlId="formBasicfullName">
                                    <Form.Control name={"name"} type="Full name" placeholder="Full Name"
                                                  className={'rounded-1'} value={formData.name} onChange={handleChange}/>
                                </Form.Group>
                                <Form.Group className="col-6 mb-3" controlId="formBasicEmail">
                                    <Form.Control name={"emailAddress"} type="email" placeholder="Email address"
                                                  className={'rounded-1'} onChange={handleChange} value={formData.emailAddress}/>
                                </Form.Group>
                            </div>
                            <Form.Group className="col-12 mb-3" controlId="formBasicDesc">
                                <Form.Control name={"description"} as="textarea" rows={4}
                                              placeholder="Tell us how you would like to be part of the early adopter journey."
                                              className={'rounded-1'} onChange={handleChange} value={formData.description}/>
                            </Form.Group>
                            <button className={'btn btn-warning w-25 fw-bold rounded-1'} >
                                Subscribe
                            </button>
                        </form>

                    </div>
                </div>
            </div>
        </section>
    </div>
}

export default LandingPage;
