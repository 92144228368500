import {toast} from "./ToastManager";


export class AllToasts {
    static success (title: string,duration?:number,showLink?:boolean,copylink?:string) {
        console.log("#% dr",duration,copylink);
        return toast.show({
            title: title,
            duration: duration ?? 3000,
            showLink:showLink??false,
            copyLink: copylink,
        });
    }

    static failure (title: string){
        return toast.show({
            title: title,
            duration: 3000,
            isSuccess: false
        });
    }
}

